
import SEO from "@/mixins/seo";
export default {
  name: "home",
  layout: "default",
  mixins: [SEO],
  data() {
    return {
      promo: {
        title: "",
        subtitle: "",
        applicationForm: true,
        button: {},
        image: {},
        locationData: {
          label: "",
          iconName: "",
          itemList: [],
          selectedValue: "",
          selectedText: "",
        },
        eventData: {
          label: "",
          iconName: "",
          itemList: [],
          selectedValue: "",
          selectedText: "",
        },
      },
      components: [],
      showModal: false,
      showVideoUrl: "",
      popUp: {},
      fetched: false,
      seo: {
        metaTitle: "",
        metaDescription: "",
        keywords: "",
        canonicalURL: "",
        shareImage: {},
      },
    };
  },
  async fetch() {
    const {
      data: { hero, details, SEO },
    } = await this.$axios.get("/home");

    const siteSettingsData = await this.$axios.get(`/site-settings`);
    this.popUp = siteSettingsData.data.popUp;

    // SEO
    this.seo = this.seo ? SEO : hero;
    const { data: locations } = await this.$axios.get("/locations");

    /*hero*/
    const {
      title,
      subtitle,
      image,
      button,
      heroCTAWidget: { locationText, eventText },
      applicationForm,
    } = hero;

    Object.assign(this.promo, {
      title,
      subtitle,
      image,
      button,
      applicationForm,
    });

    this.promo.locationData = {
      label: locationText,
      iconName: "location.svg",
      selectedItem: { text: "", value: "" },
      locationList: locations,
      itemList: locations.map((location) => {
        return {
          value: location.id,
          text: location.title,
        };
      }),
    };

    this.promo.eventData = {
      label: eventText,
      iconName: "heart.svg",
      selectedItem: { text: "", value: "" },
      itemList: [],
    };

    // Components
    this.components = await details.filter((item) => item.__component);
    this.fetched = true;
  },
  methods: {
    playVideo(videoUrl, showModal) {
      this.showVideoUrl = videoUrl;
      this.showModal = showModal;
    },
    homeVideoModal() {
      this.showModal = this.popUp.enabled;
      this.showVideoUrl = this.popUp.videoURL;
    },
    closeModal() {
      this.showModal = false;
      this.showVideoUrl = "";
      if (
        !!this.popUp &&
        !!this.popUp.enabled &&
        sessionStorage.getItem("homeModal") === null
      ) {
        sessionStorage.setItem("homeModal", "true");
      }
    },
    async getEventsByLocation(locationId) {
      const { data: events } = await this.$axios.get(
        `/events?location.id=${locationId}`
      );

      this.promo.eventData.itemList = events
        .filter((item) => {
          if (item.applicationStatus == "open") return item;
        })
        .sort(
          (x, y) =>
            new Date(x.eventDates[0].date) - new Date(y.eventDates[0].date)
        )
        .map((event) => {
          return {
            value: event.id,
            text: event.title,
          };
        });

      this.promo.eventData.eventList = events;
    },
    selectLocation({ value }) {
      this.promo.eventData.selectedItem = { text: "", value: "" };
      this.getEventsByLocation(value);
    },
  },
  mounted() {
    if (
      !!this.popUp &&
      !!this.popUp.enabled &&
      sessionStorage.getItem("homeModal") === null
    ) {
      this.homeVideoModal();
    }
  },
};
