
export default {
  name: "Promo",
  props: {
    promo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedLocation: undefined,
      selectedEvent: undefined,
    };
  },
  methods: {
    selectLocation({ text, value }) {
      this.selectedLocation = this.promo.locationData.locationList.find(
        (x) => x.id === value
      );

      this.$emit("selectLocation", { text, value });
    },

    selectEvent({ text, value }) {
      this.selectedEvent = this.promo.eventData.eventList.find(
        (x) => x.id === value
      );
    },
  },

  computed: {
    formUrl() {
      let queryParams = !!this.selectedLocation
        ? `/?location=${this.selectedLocation.id}`
        : "";
      queryParams += this.selectedEvent
        ? `&association=${this.selectedEvent.association.slug}`
        : "";
      queryParams += this.selectedEvent
        ? `&event=${this.selectedEvent.slug}`
        : "";
      let url = `${this.promo.button.url}${queryParams}`;

      return url;
    },
  },
};
