
export default {
  name: "Button",
  props: {
    label: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "link",
    },
    external: {
      type: Boolean,
      default: false,
    },
    enabled: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
