export default {
  head() {
    if (this.seo && this.seo !== null && this.seo !== undefined) {
      return {
        title: !!this.seo.metaTitle ? this.seo.metaTitle : 'Şehrin İyi Hali',
        htmlAttrs: {
          lang: 'tr'
        },
        meta: [{
            hid: 'description',
            name: 'description',
            content: !!this.seo.metaDescription ? this.seo.metaDescription : 'Şehrin İyi Hali',
          },
          // OG Metas
          {
            hid: 'og:title',
            property: 'og:title',
            content: !!this.seo.metaTitle ? this.seo.metaTitle : 'Şehrin İyi Hali',
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: !!this.seo.metaDescription ? this.seo.metaDescription : 'Şehrin İyi Hali',
          },
          {
            hid: 'og:type',
            property: 'og:type',
            content: 'website',
          },
          {
            hid: 'og:site_name',
            name: 'site_name',
            property: 'og:site_name',
            content: 'Şehrin İyi Hali',
          },
          {
            hid: 'og:author',
            name: 'author',
            property: 'og:author',
            content: 'Şehrin İyi Hali',
          },
          {
            hid: 'og:locale',
            property: 'og:locale',
            content: 'tr_TR',
          },
          {
            hid: 'og:image',
            property: 'og:image',
            content: !!this.seo.shareImage && !!this.seo.shareImage.url ? process.env.CDN_URL + this.seo.shareImage.url : './Şehrin İyi Hali_og-image.jpg',
          },
          {
            hid: 'og:url',
            property: 'og:url',
            content: !!this.seo.canonicalURL ? this.seo.canonicalURL : process.env.CDN_URL + this.$route.path,
          },
          {
            hid: 'og:keywords',
            name: 'keywords',
            property: 'og:keywords',
            content: !!this.seo.keywords ? this.seo.keywords : 'Şehrin İyi Hali' || 'Şehrin İyi Hali',
          },
          {
            hid: 'twitter:image',
            name: 'twitter:image',
            property: 'twitter:image',
            content: !!this.seo.shareImage && !!this.seo.shareImage.url ? process.env.CDN_URL + this.seo.shareImage.url : './Şehrin İyi Hali_og-image.jpg',
          },
          {
            hid: 'twitter:site',
            name: 'site',
            property: 'twitter:site',
            content: '@Şehrin İyi Hali',
          },
          {
            hid: 'twitter:card',
            name: 'twitter:card',
            property: 'twitter:card',
            content: 'summary_large_image',
          },
          {
            hid: 'twitter:creator',
            name: 'twitter:creator',
            property: 'twitter:creator',
            content: '@Şehrin İyi Hali',
          },
          {
            hid: 'twitter:title',
            name: 'twitter:title',
            property: 'twitter:title',
            content: !!this.seo.metaTitle ? this.seo.metaTitle : 'Şehrin İyi Hali',
          },
          {
            hid: 'twitter:description',
            name: 'twitter:description',
            property: 'twitter:description',
            content: !!this.seo.metaDescription ? this.seo.metaDescription : 'Şehrin İyi Hali',
          },
          {
            hid: 'twitter:image:alt',
            name: 'twitter:image:alt',
            property: 'twitter:image:alt',
            content: !!this.seo.shareImage && !!this.seo.shareImage.alternativeText ? this.seo.shareImage.alternativeText : 'Şehrin İyi Hali Og Image',
          },
        ],
      };
    } else {
      return {
        htmlAttrs: {
          lang: 'tr'
        },
        title: 'Şehrin İyi Hali'
      }
    }
  }
};