
export default {
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    overflowing: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "checked",
    event: "updateModel",
  },

  data() {
    return {
      value: this.checked,
    };
  },

  methods: {
    changed(e) {
      this.$emit("updateModel", this.value);
    },
  },
};
