
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    iconName: {
      type: String,
      default: "",
    },
    itemList: {
      type: Array,
      required: true,
    },
    selectedItem: {
      type: Object,
      default: () => ({
        text: undefined,
        value: undefined,
      }),
    },
    required: {
      type: Boolean,
      default: false,
    },
    cssClass: {
      type: String,
      default: "px-5 h-16",
    },
    emptyMessage: {
      type: String,
      default: "Önce konum seçmelisin ↑",
    },
  },

  model: {
    prop: "selectedItem",
    event: "updateModel",
  },

  data() {
    return {
      showList: false,
    };
  },

  methods: {
    toggleList() {
      this.showList = !this.showList;
    },

    close() {
      this.showList = false;
    },

    select({ value, text }) {
      this.$emit("updateModel", { value, text });
    },
  },
};
