
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    required: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    mask: {
      type: String,
      default: "",
    },
    maxLength: {
      type: Number,
    },
  },
  model: {
    prop: "text",
    event: "updateModel",
  },
  data() {
    return {
      focused: false,
    };
  },

  methods: {
    handleFocus() {
      this.focused = true;
    },

    handleFocusout() {
      this.focused = false;
    },

    handleInput(e) {
      this.$emit("updateModel", e.target.value);
    },

    keypress($event) {
      if (this.maxLength && this.maxLength === $event.target.value.length) {
        $event.preventDefault();
        return;
      }
      if (this.type !== "number") {
        return;
      }

      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },

    clicked() {
      this.$refs.input.focus();
    },
  },

  computed: {
    showLabel() {
      return !this.focused && this.text.trim().length === 0;
    },
  },
};
