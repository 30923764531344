
export default {
  name: "count",
  props: ["to"],
  data() {
    return {
      count: 0,
      interval: null,
      counterOffsetTop: null,
      windowInnerHeight: null,
    };
  },
  computed: {
    increment() {
      return Math.ceil(this.to / 60);
    },
  },
  mounted() {
    this.resize();
    window.addEventListener("resize", this.resize);
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    async resize() {
      this.counterOffsetTop = await this.$refs.counter.offsetTop;
      this.windowInnerHeight = await window.innerHeight;
    },
    async handleScroll() {
      if (window.scrollY >= this.counterOffsetTop - this.windowInnerHeight/1.5)
        this.interval = setInterval(this.tick, 60);
    },
    tick() {
      if (this.count + this.increment >= this.to) {
        this.count = this.to;

        return clearInterval(this.interval);
      }

      return (this.count += this.increment);
    },
  },
};
