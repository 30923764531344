
export default {
  props: {
    image: {
      type: Object,
      default() {},
    },
    videoUrl: {
      type: String,
      required: true,
    },
  },

  methods: {
    playVideo() {
      this.$emit("play", this.videoUrl);
    },
  },
};
