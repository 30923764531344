
export default {
  name: "VideoModal",
  props: {
    videoEmbedUrl: {
      type: String,
      default: "",
    },
    modalStatus: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    closeModal() {
      this.$emit("close");
    },
  },

  computed: {
    videoEmbedId() {
      const arr = this.videoEmbedUrl
        .split("/")
        .filter((item) => item.length > 0);

      return arr.length > 0 ? arr.pop() : "";
    },
  },
};
