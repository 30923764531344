
export default {
  props: {
    data: {
      type: Object,
      default() { }
    }
  },
  data() {
    return {
      kvkkPage: { title: "", content: "", modalStatus: false },
      formData: {
        name: "",
        email: "",
        gdpr: false,
      },
      isInvalid: {
        name: '',
        email: '',
        gdpr: ''
      },
      errorMessage: '',
      success: false,
    }
  },
  async fetch() {
    const { data: pages } = await this.$axios.get(
      "/pages?slug=kisisel-verilerin-korunmasi"
    );
    if (pages.length > 0) {
      this.kvkkPage.title = pages[0].details.filter(
        (component) => component.__component === "shared.hero"
      )[0].title;

      this.kvkkPage.content = pages[0].details.filter(
        (component) => component.__component === "sections.simple-text"
      )[0].content;

      this.kvkkPage.modalStatus = false;
    }
  },
  methods: {
    isEmail(e) {
      const emailRegex =
        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const email = emailRegex.test(String(e))
      return email ? (this.isInvalid.email = true) : false
    },
    submitForm() {
      try {
        this.isInvalid.name = this.formData.name.length >= 4
        this.isInvalid.email = this.isEmail(this.formData.email)
        this.isInvalid.gdpr = this.formData.gdpr
        if (
          (this.isInvalid.name = this.formData.name.length >= 4) &&
          (this.isInvalid.email = this.isEmail(this.formData.email)) &&
          (this.isInvalid.gdpr = this.formData.gdpr)
        ) {

          this.isInvalid.fullname = this.formData.fullname
          this.isInvalid.email = this.formData.email
          this.errorMessage = ''
          this.success = true
          const data = {
            name: this.formData.name,
            email: this.formData.email,
            gdpr: this.formData.gdpr
          }
          this.$axios.post('/subscribers', data)
        } else {
          this.errorMessage = 'Bu alanı doldurmanız zorunludur.';
          this.isInvalid.gdpr = 'Bu alanı doldurmanız zorunludur.';
          const invalidField = document.querySelector('input:invalid')
          return
          // throw new Error(`${invalidField} is not complete.`)
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err)
      }
    },
  }
}
