
export default {
  name: "",
  props: {
    data: {
      type: Object,
      default() {},
    },
  },
};
