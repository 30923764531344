
export default {
  name: "SliderClients",
  data() {
    return {
      associations: [],
      associationsSlickSettings: {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 10,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 10,
            },
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 8,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 6.5,
            },
          },
          {
            breakpoint: 790,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 540,
            settings: {
              slidesToShow: 2.7,
            },
          },
        ],
      },
    };
  },
  async fetch() {
    const { data } = await this.$axios.get("/associations?_sort=title:ASC");
    this.associations = data;
  },
};
