import { render, staticRenderFns } from "./index.vue?vue&type=template&id=7e4d1980&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalVideo: require('/usr/app/components/modal/Video.vue').default,Modal: require('/usr/app/components/modal/Modal.vue').default,HeroPromo: require('/usr/app/components/hero/Promo.vue').default,SliderClients: require('/usr/app/components/slider/Clients.vue').default,SectionsGeneric: require('/usr/app/components/sections/Generic.vue').default,SectionsNumbered: require('/usr/app/components/sections/Numbered.vue').default,SectionsFeatured: require('/usr/app/components/sections/Featured.vue').default,SectionsBanner: require('/usr/app/components/sections/Banner.vue').default,Newsletter: require('/usr/app/components/newsletter/Newsletter.vue').default})
