
export default {
  name: "Banner",
  props: {
    data: {
      type: Object,
      default() {},
    },
  },
  data() {
    return {
      sliderSettings: {
        infinite: true,
        slidesToScroll: 1,
        autoplay: true,
        speed: 200,
        autoplaySpeed: 4000,
        fade: true,
        arrows: false,
        dots: false,
        touchMove: true,
        pauseOnHover: true,
      },
    };
  },
};
