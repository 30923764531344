
export default {
  name: "",
  props: {
    data: {
      type: Object,
      default() {},
    },
  },
  data() {
    return {
      featuredSliderSettings: {
        dots: false,
        focusOnSelect: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        touchThreshold: 5,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 2.5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1.3,
              slidesToScroll: 1,
            },
          },
        ],
      },
      showModal: false,
      showVideoUrl: "",
    };
  },
  methods: {
    playVideo(videoUrl) {
      this.showVideoUrl = videoUrl;
      this.showModal = true;
      this.$emit("playVideo", videoUrl, this.showModal);
    },
  },
};
