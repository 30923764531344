
export default {
  name: "",
  props: {
    data: {
      type: Object,
      default() {},
    },
  },
  data() {
    return {
      showModal: false,
      showVideoUrl: "",
    };
  },
  methods: {
    playVideo(videoUrl) {
      this.showVideoUrl = videoUrl;
      this.showModal = true;
      this.$emit("playVideo", videoUrl, this.showModal);
    },
  },
};
