
export default {
  name: "Modal",
  props: {
    modalStatus: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    redirectUrl: {
      type: String,
    },
    content: {
      type: String,
    },
    media: {
      type: Object,
    },
  },

  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
