
import VueMarkdown from 'vue-markdown'

export default {
  name: 'Markdown',
  components: { VueMarkdown },
  props: {
    source: {
      type: String,
      default: '',
    },
  },
  methods: {
    changeImgProvider(x) {
      // return x.replace('(/uploads/', `(${process.env.CDN_URL}/uploads/`)
      return x.split('(/uploads/').join(`(${process.env.CDN_URL}/uploads/`)
    },
  },
}
